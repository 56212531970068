import React, { useMemo, useContext } from 'react';
import { graphql } from 'gatsby';

import localeString from '@lib/locale-string';

import { LanguageContext } from '@layout/Layout';
import HeaderPadding from '@layout/HeaderPadding';
import { ChangeToRandomTheme } from '@layout/Layout';

import { usePageTitle } from '@hooks/usePageTitle';
import usePaginatedList from '@hooks/usePaginatedList';
import useFilteredList from '@hooks/useFilteredList';

import SEO from '@components/seo';
import WithErrors from '@components/WithErrors';
import Pagination from '@components/Pagination';
import { Block, Col, Layout } from '@components/Blocks';
import Card from '@components/Card';
import BlockWidth from '@components/BlockWidth';
import { ArrowDown } from '@components/Icons/Icons';
import Select from '@components/Select';

export const query = graphql`
  query CasesPageQueryNb {
    allSanityCase(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          _id
          title {
            nb
            en
          }
          image {
            asset {
              url
            }
          }
          slug {
            current
          }
          category {
            category {
              en
              nb
            }
          }
        }
      }
    }
  }
`;

const getCategories = (posts) => {
  const categories = {};
  posts?.forEach(({ category }) => {
    categories[category] = true;
  });

  return Object.keys(categories);
};

const CasePage = ({ data }) => {
  const lang = useContext(LanguageContext);

  const listData = useMemo(
    () =>
      data?.allSanityCase?.edges.map(({ node: { _id, title, slug: { current: slug }, image, category } }) => {
        return {
          id: _id,
          title: localeString(lang, title),
          image,
          category: localeString(lang, category?.category, 'nb', 'Generelt'),
          url: `/${lang}/case/${slug}`,
        };
      }),
    []
  );

  const categories = useMemo(() => getCategories(listData), []);
  const [filteredList, setActiveFilter] = useFilteredList(listData, (list, filter) =>
    list.filter(({ category }) => category === filter)
  );
  const [posts, pagination] = usePaginatedList(filteredList, { itemsPerPage: 12 });

  usePageTitle('Arbeider');
  return (
    <>
      <SEO title="Arbeider" />
      <main>
        <HeaderPadding />
        {posts?.length && (
          <BlockWidth align="right">
            <Block>
              <Layout pos="right">
                <Select
                  onChange={({ target: { value } }) => {
                    setActiveFilter(value);
                  }}
                  label="Velg kategori"
                  options={categories}
                  htmlFor="filter"
                >
                  <div className="icon">
                    <ArrowDown />
                  </div>
                </Select>
              </Layout>
            </Block>
            <Block>
              <Col type={'3-x'}>
                {posts.map((props) => (
                  <Card key={props.id} {...props} />
                ))}
              </Col>
            </Block>
            <Block>
              <Layout pos="center">
                <Pagination onNavigate={() => ChangeToRandomTheme()} {...pagination} />
              </Layout>
            </Block>
          </BlockWidth>
        )}
      </main>
    </>
    // <Fragment>
    //   <HeaderPadding />
    //   <FlexList data={listData} />
    //   <HeaderPadding />
    // </Fragment>
  );
};

export default WithErrors(CasePage);
